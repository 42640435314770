//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import colors from '@/shared/assets/scss/colorsExport.scss';

export default {
  name: 'Workouts4_CompareGraph',

  props: {
    id: {
      type: String,
      default: '',
    },
    cardio: {
      type: Number,
      default: 0,
    },
    strength: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: colors.darkgray,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    cardio_height() { return (this.cardio / 100) * 189; },
    cardio_position() { return 189 - this.cardio_height; },
    strength_height() { return (this.strength / 100) * 189; },
    strength_position() { return 189 - this.strength_height; },
  },

};
